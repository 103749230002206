@font-face {
  font-family: "Inter-Medium";
  src: url(../assets/fonts/Inter-Medium.ttf);
}

@font-face {
  font-family: "Inter-Regular";
  src: url(../assets/fonts/Inter-Regular.ttf);
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Inter-Regular";
}

body {
  background: var(--background-color);
}

.container {
  display: flex;
  /* margin-top: 80px; */
  width: 70px;
}

main {
  width: 100%;
  padding: 20px;
}

.sidebar {
  color: #fff;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background:  linear-gradient(180deg, #095689 0%, #0c3b59 34.9%, #07263c 66.15%),
  url("../assets/images/sidebarImg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
  z-index: 11;
  transition: all 0.3s;
  box-sizing: border-box;
}

.sidebar-content {
  height: 99vh;
  overflow-y: auto;
  box-sizing: border-box;
}
/* .sidebar-content:hover{
  overflow-y: scroll;
} */
/* Custom Scrollbar Styles */
.sidebar-content::-webkit-scrollbar {
  width: 8px;
}

.sidebar-content::-webkit-scrollbar-track {
  border-radius: 10px;
  width: 10px;
}

.sidebar-content::-webkit-scrollbar-thumb {
  background: var(--slider-scroll);
  border-radius: 10px;
}

.sidebar-content::-webkit-scrollbar-thumb:hover {
  background: var(--slider-scroll);
}

/* Sidebar width 284px */
.sidebar .sidebarLeft {
  display: flex;
  align-items: center;
  /* background-color: #fff; */
  /* padding-top: 14px !important; */
}

.sidebarLeft .logo {
  padding: 0;
}

.sidebarToggle {
  margin: auto 24px !important;
  width: 22px !important;
}

.sidebar .navLeft {
  height: 79px;
  padding-top: 30px;
  margin-bottom: 20px;
}

.sidebar .menubar {
  cursor: pointer;
  display: block;
  margin: auto;
  width: 18px;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}

.link {
  display: flex;
  transition: all 0.5s;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-family: "Inter-Regular";
  height: 55px;
  transition: color 0.15s ease-in;
}

.link:focus {
  outline: none !important;
}

/* .sidebar .active {
  background: rgba(255, 255, 255, 0.20);
  border-left: 3px solid var(--white-color);
} */
.sidebar .active {
  position: relative;
}

.sidebar .active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-left: 3px solid var(--white-color);
}

.sidebarLeft .icon {
  margin: auto 24px !important;
}

.link:hover {
  background: rgba(255, 255, 255, 0.2);
}

.sidebar .icon {
  display: block;
  margin: auto;
  width: auto;
  height: auto;
}

.sidebar .icon img {
  width: 24px;
  height: 24px;
}

/* .sidebar .icon .sidebar-icons {
  width: 27px;
  height: 27px;
} */

.link .icon .community-dictionary {
  height: 20px;
}

.sidebar .active.link .icon .community-dictionary {
  padding-left: 0 !important;
}

.settings-link {
  margin-top: 60px;
}

.link_text {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  padding: 17px 0;
}

.nav {
  color: var(--color-black);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0 0 0 20px;
  height: 80px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

/* .navbar-bg {
  background-color: #FFF;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 3px rgba(0, 72, 119, 0.15);
} */

/* Navbar scrolling background */
.nav-bg-color {
  background-color: var(--background-color);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 3px rgba(0, 72, 119, 0.15);
  transition: all 0.3s ease-in;
}

/* nav bg color on dashboard page */
.dashboard-page-bg {
  background-color: var(--overview-nav-bg);
}

.nav-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  padding: 0 75px;
}

.nav-right {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.nav-right-item {
  height: 80px;
  padding: 0 16px;
  /* border-left: 1px solid #CCDAE4; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--dark-svg-color);
}

.nav-right-item .username {
  color: var(--dark-color);
  text-align: left;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.user-name span:nth-child(2) {
  color: var(--graph-date);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
}

.user-name span:nth-child(3) {
  color: #3bbee8;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  margin-top: 10px;
}

.user-name span:nth-child(3) svg {
  margin-top: 2px;
}

.menubar {
  cursor: pointer;
}

.grid-container {
  display: grid;
  grid-template-columns: 0.05fr 1fr;
  /* grid-gap: 10px; */
  margin-top: 80px;
}

/* .link:nth-child(12) {
  margin-top: auto;
} */

.ant-pagination .anticon {
  vertical-align: center;
}

.client-pagination .ant-select-selector .ant-select-selection-search {
  inset-inline-start: 28px;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}

.logout {
  padding-bottom: 10px;
}

.logout-Btns {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}

.logout-Btns button {
  height: 48px;
  border-radius: 8px;
  padding: 16px 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout-cancel {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.logout-button {
  background: var(--polstrat-primary) !important;
  color: var(--white-color);
}

.toggle-btn {
  height: 22px !important;
  cursor: pointer;
}

.user-profile {
  position: relative;
  /* display: inline-block; */
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip1 {
  position: absolute;
  top: 150%;
  right: -160px;
  transform: translateX(-50%);
  width: 316px;
  height: 152px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--wrapper-bg);
  box-shadow: 0px 2px 12px 0px var(--modal-opacity);
  border: 2px solid var(--border);
  display: flex;
  align-items: center;
  padding-left: 30px;
}

.user-profile img {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 93px;
  border: 1.938px solid var(--border-line, #ccdae4);
  box-shadow: 0px 0px 11.625px 0px rgba(0, 0, 0, 0.25) inset;
  /* margin-left: 20px; */
}

.user-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin-left: 20px;
  text-align: left;
}

.nav-right-item input {
  height: 35px;
  width: 320px;
  border-radius: 8px;
  padding: 2px 10px;
  border: 1px solid #ccdae4;
  border-radius: 8px;
  line-height: 36px;
}

.required-field {
  color: red;
  margin-left: 4px;
}

.notification-dot {
  width: 9px;
  height: 9px;
  background: red;
  position: absolute;
  right: 5.5%;
  top: 39.5%;
  border-radius: 20px;
  border: 1px solid white;
}
