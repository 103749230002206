.client-page-section {
  margin: 0 16px;
  padding: 0;
  box-sizing: border-box;
}

/* Client */

.client-page-section .page-heading button:hover {
  background: #004877 !important;
}

.client-page-section:where(
    .css-dev-only-do-not-override-k83k30
  ).ant-table-wrapper
  .ant-table-pagination.ant-pagination {
  display: none;
}

.client-page-section .table-wrapper {
  padding-top: 16px;
}
.client-page-section .search-filter .filter {
  border: 1px solid #ccdae4;
  font-size: 14px;
  height: 40px;
  padding: 10px;
  border-radius: 8px;
  margin-right: 10px;
}

.client-page-section .search-filter .search {
  display: flex;
  flex-direction: row;
}

.client-page-section .search svg {
  position: absolute;
  z-index: 1;
  margin: 10px 4px 0 4px;
}

.search input {
  text-indent: 20px;
}

.search input::placeholder {
  text-indent: 20px;
}

.table-wrapper .table {
  margin: 20px;
  border: 1px solid blue;
}

.table-row {
  display: block !important;
}

.client-page-section .p-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.table-row .ant-table-thead .ant-table-cell {
  background-color: #f0f6f9;
}

.table-row .ant-table-row:nth-child(even) {
  background-color: #f0f6f9;
}

.search input {
  margin-right: 10px;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #001d30 !important;
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 600;
  padding: 5px;
  font-size: 16px;
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-tab-btn:hover {
  color: #001d30 !important;
}

.ant-tabs-tab-active {
  background-color: #f0f6f9 !important;
}

.ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #002b47;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 48px!important;
  border-radius: 8px;
  margin-top: 8px;
}

.ant-tabs .ant-tabs-tab {
  padding: 12px 5px;
}

.usage-stats-wrapper .overview-stats {
  border: 1px solid #ccdae4;
}

.client-info .br-color {
  display: inline;
  margin-top: 40px;
}
.client-info .office-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #2b9fff;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 8px;
  border: 1px solid rgba(43, 159, 255, 0.5);
  padding: 15px 20px 30px;
}
.client-info .office-data {
  display: inline-block;
  margin-right: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1c2d37;
  line-height: 17px;
  padding: 12px;
  text-transform: capitalize;
  background: #ffffff;
  border: 1px solid #ccdae4;
  border-radius: 20px;
}

.cancel-office-btn .anticon svg {
  font-size: 14px;
  margin-left: 8px;
}

.user-table-inner .ant-table-tbody > tr > td {
  text-transform: capitalize;
}

.client-page-section .table-wrapper {
  padding-top: 15px;
  padding-bottom: 0;
}

.client-details p {
  display: flex;
  text-align: justify;
  width: 50%;
}

.client-details .details-value {
  margin-left: 50px;
  text-align: justify;
}

.user-table-inner.ant-table-wrapper .ant-table-pagination.ant-pagination {
  display: none !important;
}

.client-page-section .anticon svg {
  display: block;
  font-size: 15px;
  font-weight: bold !important;
}

.client-page-section .dropdown-content {
  height: auto;
}

.client-page-section .dropdown-content div {
  cursor: pointer;
  text-align: left;
  padding-bottom: 4px;
}

.ant-select-item-option-group-label {
  font-size: 16px;
  color: #333;
} 

.ant-table-wrapper td.ant-table-column-sort {
  background: transparent;
}

.client-info .add-user-modal-radio .radio label {
  opacity: 1 !important;
}

.client-info .prev-button {
  width: 116px;
  height: 48px;
  border: 1px solid var(--btn-border);
  border-radius: 8px;
  color: var(--btn-text);
  font-weight: 600;
}
.client-details {
  background-color: var(--wrapper-bg);
  border-radius: 0px 0px 10px 10px;
}

.client-info-stats {
  border-radius: 10px;
  background: var(--wrapper-bg);
  margin: 20px 0px;
  padding: 20px 0px;
}

.client-info-stats img {
  width: auto;
  height: 45px;
}

.client-info-stats h6 {
  color: var(--client-stat-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.client-info-stats p {
  color: var(--client-stat-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 8px;
}

.client-info-stats-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.client-details .firstname-field p span:first-child {
  width: 25% !important;
  color: var(--body-text);
  opacity: 0.65;
  font-weight: 400;
}
.client-details .firstname-field p span:last-child {
  color: var(--body-text);
  font-weight: 400;
}
.client-details .firstname-field p {
  padding-bottom: 12px !important;
}
.gms-client-table1
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td:first-child {
  width: 32%;
}

.gms-client-table1
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td:nth-child(2) {
  font-weight: 600;
}

.client-info-heading h6 {
  color: #fff;
  font-size: 12.353px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 70px;
  height: 19px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-left: 10px;
}

.client-table
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td:first-child {
  font-weight: 600;
}

.tab-heading1 {
  border-bottom: 1px solid #e9e7fd;
}

.cllient-details-section1 {
  width: 80%;
}

.subscription-tag {
  color: #6d6308;
  border-radius: 20.5px;
  background: #e1cb05;
  font-size: 12.353px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: auto;
  padding: 0 12px;
  height: 19px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-left: 10px;
}

.client-breadcrumbs {
  border-radius: 8px;
  border: 1px solid #2b9fff;
  padding: 10px 15px;
}

.client-breadcrumbs p span:nth-child(1) {
  color: #667085;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.client-breadcrumbs p span:nth-child(2) {
  color: var(--primary-color);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.added-user div {
  width: 336px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  margin-right: 15px;
}

.active-status {
  background-color: #27ae60;
  color: white;
}

.inactive-status {
  background-color: rgb(219, 53, 53);
  color: white;
}

.client-modal .ant-btn.primary-btn {
  border-radius: 6px !important;
}

.add-modal-row .client-start-date .ant-picker {
  width: 326px !important;
}
.client-user-list{
  border-radius: 0 0 8px 8px;
  border-bottom: none!important;
}
.client-user-list .modal-wrapper-content {
  padding: 0px !important;
}

/* Custom Scrollbar Styles */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #ccc #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-radius: 10px;
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #7a7a7a;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #7a7a7a;
}

.client-user-list .ant-table-wrapper table {
  border-radius: 0px 0px 10px 10px !important;
  border-bottom: none !important;
}

.client-user-list .modal-wrapper-content .ant-table-wrapper .ant-table-tbody > tr > td {
  height: 69px;
}

.client-user-list .modal-wrapper-content thead {
  height: 69px !important;
}

.client-details .cllient-details-section1 .firstname-field p span:first-child {
  width: 30% !important;
}

/* CSS for the user table */
.user-table {
  width: 75%;
  border-collapse: collapse;
  border-radius: 5;
}

.user-table th,
.user-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}
.user-table tr>td,
.client-user-table strong{
  color: var(--body-text);
}
.user-table th {
  background-color: #f0f0f0;
}

.remove-button {
  background-color: #dfe0e0;
  color: rgb(119, 90, 90);
  border: none;
  cursor: pointer;
  padding: 5px 10px;
}

.add-button {
  background-color: #dfe0e0;
  color: rgb(42, 11, 164);
  border: none;
  cursor: pointer;
  padding: 5px 10px;
}
.planType input {
  opacity: 1;
}

.planType .ant-select-selector label {
  opacity: 1;
}

.planType
  .ant-select-multiple.ant-select-lg
  .ant-select-selection-item-remove
  > .anticon {
  vertical-align: 0.1em;
}

.add-modal-row .planType .ant-select-selector {
  height: auto !important;
  padding: 5px 5px 5px 14px;
}
.add-modal-row .planType .ant-select-selection-item {
  line-height: 30px !important;
  border: .25px solid var(--body-text);
}
.plan-amt span{
  padding-block: 12px;
  color: var(--body-text);
}
.phone-input .ant-input-group >.ant-input:last-child, :where(.css-dev-only-do-not-override-k83k30).ant-input-group .ant-input-group-addon:last-child {
  border-left: none!important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: var(--calendar-picker);
}
.client-first-name input{
  width: 75%!important;
}
.client-first-name .ant-select-selection-item {
  text-overflow: unset!important;
}