.page-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:16px 0;
}

.page-heading h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color:var(--dark-color);
    padding-top: 8px;
}

.page-heading button {
    width: auto;
    height: 48px;
    background: var(--polstrat-primary);
    border-radius: 8px;
    align-items: center;
    color: var(--white-color);
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    display: inline-flex;
}
.page-heading button svg{  
    margin-right: 5px;
    height: 24px;
    width: 20px;
}
.page-heading button:hover{
    color: var(--white-color)!important;
    border-color:#222222!important;
}