.notification-item {
  display: flex;
  height: 80px;
  border-bottom: 1px solid #ccdae4;
  padding: 10px 0;
  /* background-color: #F0F6F9; */
}
/* .notification-item:first-child {
  border-radius: 10px 10px 0px 0px;
}

.notification-item:last-child {
  border-radius: 0px 0px 10px 10px;
} */

.child2 {
  flex: 1;
}

/* .child3 {
    flex: 0.1;
} */

.notification-content {
  padding-left: 8px;
  padding-right: 5px;
}

.icon-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-item .icon-div .icon {
  width: 40px;
  height: 40px;
  padding: 0 !important;
  margin: 15px 8px 15px 12px;
}

.notification-item-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: 8px;
  font-style: normal;
  color: var(--notification-title);
}

.notification-content-item {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--notification-content);
}

.time-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-div p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  line-height: 18px;
  text-align: right;
  color: var(--notification-content);
}
