/* .text-heading{
    padding-top: 15px;
    font-size: 22px;
    font-weight: bold;      
} */
.tab-heading{
padding: 10px 0px;
color: var(--primary-color);
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
/* margin-bottom: 15px;    */
}
/* .basicInfoDiv{
    display: flex;
    width: 43rem;
} */
.fNameDiv{
    width: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    flex: 1;
}
.sNameDiv{
    width: 20px;
    padding-bottom: 20px;
    flex: 1;
}
/* .form-end{
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
} */

.client-details{
    padding: 20px;
}

.basicInfoDiv input{

    height: 48px;
    margin-top: 10px;
    margin-bottom: 15px;

}

.basicInfoDiv .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 48px;
    margin-top: 10px;
    margin-bottom: 15px;
    padding: 0 11px;
}

.ant-picker .ant-picker-input >input{
    width: 100%;
    padding: 0 11px; 
}


.client-usage-graph-section{
margin: 20px;
border: 1px solid #CCDAE4;
border-radius: 8px;
box-shadow: 1px 1px 3px rgba(0, 72, 119, 0.15);
}

.client-usage-header h1{
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001D30;
    padding: 15px;
    border-bottom: 1px solid #CCDAE4;
    
}
.firstname-field p{
    width: 100%;
    padding-bottom: 18px!important;
}
.firstname-field p span:first-child{
   display: inline-block;
   width: 20%;
}

.tabs1 .gms-table-tabs .tab-btn2{
    width: 180px !important;
}

