.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

#video-bg {
  position: absolute;
  left: 22%;
  /* min-width: 100%; 
  min-height: auto; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.get-in .logo {
  padding: 0;
  display: block;
  margin-inline: auto;
  padding-bottom: 100px;
}

.get-in .logo-submited{
  padding: 0;
  display: block;
  margin-inline: auto;
  padding-bottom: 30px;

}
/* .get-in .logo::after {
  content: "Super Admin Dashboard";
  position: relative;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.96px;
  padding-block: 15px;
  color: var(--modal-h3);
} */
.signup-page {
  /* height: 100vh;
  background-color: var(--light-color); */
  position: relative;
  z-index: 1;
  height: 100vh;
}

/* .signup-page-background {
  background: linear-gradient(180deg, #095689 0%, #0C3B59 34.90%, #07263C 66.15%), url('../assets/images/servicesBg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: soft-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signup-page-background h1 {
  color: #FFF;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.96px;
  padding: 50px 0 14px;
}

.signup-page-background p {
  color: rgba(255, 255, 255, 0.70);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 22px;
  max-width: 497px;
} */

.get-in {
  max-width: 45%;
  /* margin: auto; */
  background: var(--input-bg);
  width: -webkit-fill-available;
  border-radius: 0px 50px 50px 0px;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: center;
  margin: auto;
  padding-inline: 5%;
  /* box-shadow: 1px 1px 3px 0px rgba(0, 72, 119, 0.15); */
  box-shadow: 0 2px 5px 0 var(--client-stat-text);
}

.get-in form {
  width: -webkit-fill-available;
}

.login-content {
  max-width: 496px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: auto;
}

* {
  box-sizing: border-box;
}

.get-start {
  max-width: 496px;
  border: 1px solid #CCDAE4;
  margin: auto;
  margin-top: 5%;
  border-radius: 8px;
  padding: 2rem;
  height: 633px;
  background: var(--white-color);
}

.text-content {
  height: 39px;
}

.signup-page h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  color: var(--modal-h3);
}

.sign-in-para {
  height: 22px;
  margin-block: 8px;

}

.sign-in-para p {
  font-weight: 400;
  font-size: 18px !important;
  line-height: 21px;
  font-style: normal;
  color: var(--body-text);
}

.text-error {
  color: rgb(161, 14, 4);
}

.signup-page .animate-spin {
  width: 1.5rem;
  height: 1.5rem;
}

.signup-page button:disabled {
  opacity: 0.7;
}

.forgot-pass-text {
  color: var(--primary-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.forgot-pass-text:hover{
  text-decoration: underline;
}

.get-password {
  max-width: 496px;
  margin: auto;
  border-radius: 8px;
  background-color: var(--white-color);
}

.get-password img {
  display: block;
  margin: auto;
  padding-bottom: 24px;
}

.signup-page .ant-input,
.signup-page .ant-input-affix-wrapper {
  line-height: 32px !important;
  background: var(--table-border)!important;
  border: var(--border-line) !important;
}

.signup-page .signin-password input.ant-input {
  border: none !important;
}

.signup-page .anticon svg {
  color: var(--primary-color);
}

.resend-link{
  color: #004877;
}